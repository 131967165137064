<template>
  <footer class="footer">
    <div class="container container_no-pd">
      <div class="footer__inner">
        <div class="grid-wrapper">
          <div class="grid grid_middle">
            <div
              class="grid-col-auto"
              v-animation="{
                name: 'fadeLeft',
                duration: 0.45,
              }"
            >
              <a
                class="footer__logo"
                href="https://vk.com/vkdesignteam"
                target="_blank"
              >
                <img src="@/assets/img/logo.svg" alt="Design team" />
              </a>
            </div>
            <div
              v-if="menu?.length"
              class="grid-col-auto"
              v-animation="{
                name: 'fadeRight',
                duration: 0.65,
              }"
            >
              <div class="footer__right">
                <nav class="footer-menu">
                  <ul class="footer-nav">
                    <li
                      v-for="(item, i) in menu"
                      :key="`footer_menu_item_${i}`"
                    >
                      <a
                        :href="item.url"
                        :target="item.target ?? null"
                        v-html="item.name"
                      />
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  setup() {
    const menu = [
      {
        name: "Спикеры",
        url: "#speakers",
      },
      {
        name: "Расписание",
        url: "https://www.figma.com/proto/sgmfOcodWtpgEMHBy7kOh6/VK-Design-Conf-ex-Dribbble-Meetup-22%E2%80%9424?page-id=4408%3A6&node-id=4408-7&viewport=519%2C561%2C0.43&t=91driqGD0UQu4RAt-1&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=4408%3A7",
        target: "_blank",
      },
      {
        name: "Партнеры",
        url: "#partners",
      },
      {
        name: "Вакансии",
        url: "https://team.vk.company/vacancy/?specialty=273",
        target: "_blank",
      },
      {
        name: "VK JT 2024",
        url: "https://vkjt.ru/",
        target: "_blank",
      },
    ];

    return { menu };
  },
};
</script>

<style lang="scss">
$b: ".footer";

#{$b} {
  display: block;
  padding: 65px 0 36px;
  background-color: $color-black;
  color: $color-white;
  font-size: 18px;
  line-height: 1.44;

  @include desktop {
    padding-left: 2px;
    padding-right: 2px;
  }

  @include laptop {
    padding-left: 0;
    padding-right: 0;
  }

  &__inner {
    padding-left: 59px;
    padding-right: 57px;

    @include laptop {
      padding-left: $global-pd-left-tablet;
      padding-right: $global-pd-right-tablet;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > * {
      &:not(:last-child) {
        margin-right: 35px;
      }
    }
  }

  &__logo {
    display: flex;
    width: 291px;
    height: 40px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & > li {
      &:not(:last-child) {
        margin-right: 50px;
      }

      & > a {
        display: inline-block;
        text-decoration: none;
        color: inherit;
        font-weight: 500;
        color: rgba($color-white, 0.7);
        transition: color 0.2s $global-hover-effect;

        &:hover {
          color: rgba($color-white, 0.99);
        }
      }
    }
  }
}
</style>
