<template>
  <div id="speakers" class="speakers">
    <div class="container">
      <div class="container__inner speakers-list-wrapper">
        <ul class="speakers-list">
          <li
            v-for="(speaker, i) in speakers"
            :key="`speaker_${i}`"
            :class="`_item-${i + 1}`"
            v-animation="getSpeakerAnimation(i)"
          >
            <component
              :is="speaker.url ? 'a' : 'div'"
              :href="speaker?.url"
              :target="speaker?.target"
              class="speaker"
              :class="[`speaker_item-${i + 1}`]"
            >
              <div class="speaker__num">
                {{ getNum(i) }}
              </div>
              <div class="speaker__names">
                <div
                  v-for="(name, k) in speaker.names"
                  :key="`speaker_name_${k}`"
                  class="speaker__name"
                  :class="{ _between: speaker.names.length > k + 1 }"
                  v-html="
                    `${name}${
                      speaker.names.length > k + 1 ? '<span>,</span>' : ''
                    }`
                  "
                />
                <div v-if="speaker.workPlace" class="arrow-el">
                  <span class="arrow-el__arrow"></span>
                </div>
              </div>
              <div
                v-if="speaker?.workPlace"
                class="speaker__work-place"
                v-html="speaker.workPlace"
              />
            </component>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    const speakers = [
      {
        names: ["Команда Mail"],
        target: "_blank",
      },
      {
        names: ["Кира Калимулина"],
        workPlace: "VK",
        target: "_blank",
      },
      {
        names: ["Анна Бичевская"],
        workPlace: "Humanity",
        target: "_blank",
      },
      {
        names: ["Наталья Климчук"],
        target: "_blank",
      },
      {
        names: ["Валерий Сироткин"],
        workPlace: "VK",
        target: "_blank",
      },
      {
        names: ["Митя Осадчук"],
        workPlace: "ex - ИВИ",
        target: "_blank",
      },
      {
        names: ["Мария Титова"],
        workPlace: "VK",
        target: "_blank",
      },
      {
        names: ["Екатерина Черкес-заде"],
        target: "_blank",
      },
      {
        names: ["Константин Лобанов"],
        workPlace: "Wemakefab",
        target: "_blank",
      },
      {
        names: ["Анна Громова"],
        workPlace: "VK",
        target: "_blank",
      },
      {
        names: ["Захар День"],
        workPlace: "ВШЭ",
        target: "_blank",
      },
      {
        names: ["и другие..."],
        target: "_blank",
      },
    ];

    const getNum = (index) => {
      const num = index + 1;
      return num > 9 ? num : `0${num}`;
    };

    const getSpeakerAnimation = (i) => {
      const index = i + 1;
      let options = {};

      switch (index) {
        case 1:
        case 4:
        case 7:
          options = {
            name: "fadeLeft",
            duration: 1,
          };
          break;

        case 2:
        case 5:
        case 8:
          options = {
            name: "fadeLeft",
            duration: 0.75,
          };
          break;

        case 3:
        case 6:
        case 9:
          options = {
            name: "fadeRight",
            duration: 0.6,
          };
          break;
      }

      return options;
    };

    return { speakers, getNum, getSpeakerAnimation };
  },
};
</script>

<style lang="scss">
$b: ".speakers";
$c: ".speaker";

#{$b} {
  padding: 20px 0 0;
  display: block;

  &-list-wrapper {
    display: flow-root;
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0 -5px -4px -5px;
    display: flex;
    flex-wrap: wrap;

    & > li {
      padding: 0 0 4px 0;

      &._item-1 {
        width: 36.181%;
      }

      &._item-2 {
        width: 63.819%;

        @include laptop {
          width: 100%;
        }
      }

      &._item-3 {
        width: 100%;
      }

      &._item-4 {
        width: 42.64%;
      }

      &._item-5 {
        width: 57.33%;

        @include laptop {
          width: 100%;
        }
      }

      &._item-6 {
        width: 100%;
      }

      &._item-7 {
        width: 46%;

        @include laptop {
          width: 100%;
        }
      }

      &._item-8 {
        width: 54%;

        @include laptop {
          width: 100%;
        }
      }

      &._item-9 {
        width: 100%;
      }

      &._item-10 {
        width: 49.4%;

        @include laptop {
          width: 100%;
        }
      }

      &._item-11 {
        width: 50.6%;

        @include laptop {
          width: 100%;
        }
      }

      &._item-12 {
        width: 100%;
      }
    }
  }
}

#{$c} {
  position: relative;
  display: flex;
  background-color: $color-dark;
  color: $color-white;
  border-radius: 56px;
  padding: 71px 40px 53px 80px;
  font-family: $font-alt;
  font-weight: 400;
  font-size: 42px;
  line-height: 1;
  transition: background-color 0.3s $global-hover-effect;
  cursor: pointer;

  @include laptop {
    padding: 60px 35px 55px 67px;
    align-items: flex-end;
  }

  &:not(#{$c}_more):hover {
    background-color: $color-brand !important;
    color: $color-white !important;
  }
  &_item-1,
  &_item-4,
  &_item-8,
  &_item-10 {
    background-color: $color-black;
  }

  &__num {
    position: absolute;
    top: 50px;
    left: 50px;
    display: inline-block;
    font-family: $font-base;
    font-weight: 300;
    font-size: 21px;
    line-height: 1.1;
    color: rgba($color-white, 0.6);

    @include laptop {
      top: 39px;
      left: 40px;
    }
  }

  &__names {
    display: flex;
    flex: 1 1 auto;
    align-items: flex-end;

    @include laptop {
      flex-wrap: wrap;
    }

    .arrow-el {
      flex: 1 1 auto;
      min-height: 1em;
      margin-right: 12px;

      &__arrow {
        &::after {
          margin-left: 0;
        }
      }
    }
  }

  &__name {
    margin-right: 13px;
    white-space: nowrap;

    &._between {
      margin-right: 0.3em;
    }

    & + & {
      @include laptop {
        margin-top: 43px;
      }
    }

    &:first-child {
      #{$c}_item-1 & {
        @include laptop {
          width: 100%;
        }
      }

      #{$c}_item-6 & {
        @include laptop {
          width: 100%;
        }
      }

      #{$c}_item-9 & {
        @include laptop {
          width: 100%;
        }
      }

      #{$c}_item-12 & {
        @include laptop {
          width: 100%;
        }
      }
    }
  }

  &__work-place {
    white-space: nowrap;

    #{$c}_item-9 & {
      @include laptop {
        min-width: 210px;
      }
    }
  }

  .arrow-el {
    &__arrow {
      &::before {
        margin: 0;
        top: auto;
        transform: none;
        bottom: 14px;
      }

      &::after {
        margin-top: 5px;
      }
    }
  }
}
</style>
