<template>
  <div class="registration">
    <div class="container">
      <div class="container__inner registration-grid-wrapper">
        <div class="registration-grid">
          <div
            class="registration-grid__left"
            v-animation="{
              name: 'fadeLeft',
              duration: 0.45,
            }"
          >
            <div class="registration__left">
              <div class="registration__title" v-html="title" />
              <div class="registration__text" v-html="text" />
              <div class="registration__buttons">
                <a
                  href="https://vk.company/ru/press/events/1267/"
                  target="_blank"
                  class="button button_border"
                >
                  <span v-html="buttonText" />
                </a>
              </div>
            </div>
          </div>
          <div class="registration-grid__right">
            <info-slider
              v-animation="{
                name: 'fadeRight',
                duration: 0.65,
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoSlider from "@/components/InfoSlider";

export default {
  components: {
    InfoSlider,
  },
  setup() {
    const title = "20 сентября московский офис VK";
    const text =
      "Все новости и&nbsp;обновления конференции теперь <a href='https://vk.com/vkdesignteam' target='_blank'>ВКонтакте</a> и&nbsp;в&nbsp;<a href='https://t.me/dribbblemeetup' target='_blank'>Telegram</a>. <br class='visible-laptop'>Подписывайтесь, чтобы ничего не&nbsp;пропустить";
    const buttonText = "Регистрация";

    return { title, text, buttonText };
  },
};
</script>

<style lang="scss">
$b: ".registration";

#{$b} {
  display: block;
  font-weight: 300;

  &-grid-wrapper {
    display: flow-root;
  }

  &-grid {
    display: flex;
    margin: 0 0 -4px -4px;

    & > * {
      padding: 0 0 4px 7px;
    }

    &__left {
      width: 571px;

      @include laptop {
        width: 527px;
      }
    }

    &__right {
      width: calc(100% - 571px);

      @include laptop {
        width: calc(100% - 527px);
      }
    }
  }

  &__left {
    height: 100%;
    background-color: $color-black;
    padding: 49px 79px 77px;
    border-radius: 56px;

    @include laptop {
      padding: 67px;
    }
  }

  &__title {
    max-width: 600px;
    display: block;
    font-family: $font-alt;
    font-weight: 400;
    font-size: 42px;
    line-height: 1;
    color: inherit;
    margin: 0 0 29px;
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: 39px;
    }
  }

  .button {
    height: 81px;
    padding: 0 34px;
  }
}
</style>
