export const animation = {
  mounted(el, binding) {
    el.settings = binding.value;
    el.observerOptions = {
      root: "body",
      rootMargin: "0px",
      threshold: 0.5,
    };

    el.classList.add(el.settings.name);
    el.style.transitionDuration = `${el.settings.duration}s`;

    el.inViewport = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("is-inview");
        }
      });
    };
    el.observer = new IntersectionObserver(el.inViewport);
    el.observer.observe(el, el.observerOptions);
  },
  unmounted(el) {
    el.classList.remove(el.settings.name);
    el.style.transitionDuration = "";
    el.observer.unobserve(el);
  },
};
