<template>
  <div
    id="partners"
    class="partners"
    v-animation="{
      name: 'fade',
      duration: 1.5,
    }"
  >
    <runline-slider :space-between="118" :speed="3000">
      <swiper-slide v-for="(partner, i) in partners" :key="`partner_${i}`">
        <div class="partner">
          <div class="partner__logo">
            <img
              :src="require(`@/assets/${partner.logo.src}`)"
              :width="partner.logo.width"
              :height="partner.logo.height"
            />
          </div>
        </div>
      </swiper-slide>
    </runline-slider>
  </div>
</template>

<script>
import { SwiperSlide } from "swiper/vue";
import RunlineSlider from "@/components/RunlineSlider";

export default {
  components: {
    SwiperSlide,
    RunlineSlider,
  },
  setup() {
    const clonedSize = 3;
    const partners = [
      {
        logo: {
          src: "img/partner-1.svg",
          width: 109,
          height: 62,
        },
      },
      {
        logo: {
          src: "img/partner-3.svg",
          width: 134,
          height: 30,
        },
      },
      {
        logo: {
          src: "img/partner-4.svg",
          width: 157,
          height: 40,
        },
      },
      {
        logo: {
          src: "img/partner-5.svg",
          width: 170,
          height: 80,
        },
      },
      {
        logo: {
          src: "img/partner-6.svg",
          width: 138,
          height: 34,
        },
      },
    ];
    const partnersLength = partners.length;

    for (let i = 1; i < clonedSize; i++) {
      for (let k = 0; k < partnersLength; k++) {
        partners.push(partners[k]);
      }
    }

    return { partners };
  },
};
</script>

<style lang="scss">
$b: ".partners";
$c: ".partner";

#{$b} {
  display: block;
  overflow: hidden;
  margin: 30px 0 0;

  .swiper-slide {
    width: auto;
  }
}

#{$c} {
  display: block;
  text-decoration: none;

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
